import './solutions.css';

import { Button, Col, Row } from 'react-bootstrap';

import Layout from 'components/layout';
import { Helmet } from "react-helmet";
import React from 'react';
import admin from 'images/admin.svg';
import dashboard from 'images/dashboard.svg';
import icon from 'images/icon.svg';
import profile from 'images/profile.svg';
import salesreps from 'images/salesreps.svg';
import { Link } from 'gatsby';

const Solutions = () => {
    return (
        <Layout>
            <div>
                <Helmet>
                    <link rel="icon" href={icon} />
                    <title>Platform to Motivate all Stakeholders in Sales</title>
                    <meta name="description" content="Each team that is a part of the overall sales process has to play a role in the incentive strategy. Our solution has been designed keeping in mind all stakeholders." />
                </Helmet>
                <div className="home">
                    <div>
                        <p className="title"> Motivate all Stakeholders in Sales</p>
                        <p className="description">Each team that is a part of overall sales process has to play a role in the incentive strategy. Hence our solution has been designed keeping in mind all stakeholders and how they are interlinked.</p>
                        <Link href='/quotacal/demo'>
                            <Button className="button" type="primary" >Schedule a Demo</Button>
                        </Link>
                    </div>
                </div>
                <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '30px' }}>
                    <Col md={{ span: 5, offset: 1, order: 'first' }} xs={{ span: 12, offset: 1, order: 'last' }} >
                        <div>
                            <p className='head'>For Sales Leaders and CXO’s</p>
                            <p className='desc'>Complete traceblity of Comp Plans with full details of every single dollar earned.</p>
                            <p className='desc'>Real-time sales performance management to make sure you achieve your quota.</p>
                            <Button style={{ background: '#FFFFFF', color: '#5958C7', padding: '8px 16px', border: '1px solid #5958C7', fontSize: '16px', borderRadius: '2px', marginRight: '20px' }}>Learn More </Button>
                        </div>
                    </Col>
                    <Col md={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }} >
                        <img className="RightImg" src={dashboard} alt="something went wrong" />
                    </Col>
                </Row>
                <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '30px' }}>
                    <Col xs={12} md={6}>
                        <img className="LeftImg" src={profile} alt="something went wrong" />
                    </Col>
                    <Col md={{ span: 5, offset: 1 }} xs={{ span: 12, offset: 1 }}>
                        <div>
                            <p className='head'>For Sales and Revenue Ops</p>
                            <p className='desc'>Your go to platform to design compensation plans for sales based on region, product and gross margins. Make your teams more succesful with higher commissionable sales.</p>
                            <Button style={{ background: '#FFFFFF', color: '#5958C7', padding: '8px 16px', border: '1px solid #5958C7', fontSize: '16px', borderRadius: '2px', marginRight: '20px' }}>Learn More </Button>
                        </div>
                    </Col>
                </Row>
                <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '30px' }}>
                    <Col md={{ span: 5, offset: 1, order: 'first' }} xs={{ span: 12, offset: 1, order: 'last' }}>
                        <div>
                            <p className='head'>For Sales Reps</p>
                            <p className='desc'>Real time visibility of the commissions earned and graphical representation of performance over time. Define the benchmarks and surpass them.</p>
                            <Button style={{ background: '#FFFFFF', color: '#5958C7', padding: '8px 16px', border: '1px solid #5958C7', fontSize: '16px', borderRadius: '2px', marginRight: '20px' }}>Learn More </Button>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <img src={salesreps} className="RightImg" alt="something went wrong" />
                    </Col>
                </Row >
                <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '40px' }}>
                    <Col md={6} xs={12}>
                        <img src={admin} className="LeftImg" alt="something went wrong" />
                    </Col>
                    <Col md={{ span: 5, offset: 1 }} xs={{ span: 12, offset: 1 }}>
                        <div>
                            <p className='head'>For Admin and Finanace</p>
                            <p className="desc">We handle ASC340-50 and IFRS. From sales incentives calculation to payouts of the commissions - use a single dashboard to track everything.</p>
                            <Button style={{ background: '#FFFFFF', color: '#5958C7', padding: '8px 16px', border: '1px solid #5958C7', fontSize: '16px', borderRadius: '2px', marginRight: '20px' }}>Learn More </Button>
                        </div>
                    </Col>
                </Row>
                <Row style={{ justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
                    <Col xs={12} >
                        <p style={{ color: '#5958C7', fontSize: '40px', fontFamily: 'inria-sans', fontWeight: 'bold', fontStyle: 'normal' }}>We think QuotaCal can motivate your sales team. Give it a try.</p>
                        <Link href='/quotacal/demo'>
                            <Button className="button" type="primary" >Schedule a Demo</Button>
                        </Link>
                        <p style={{ color: '#000000', fontSize: '20px', opacity: '0.5', marginBottom: '50px', marginTop: '30px' }}>Manage your plans on QuotaCal incentive Compensation platform.</p>
                    </Col>
                </Row>
            </div >
        </Layout>
    )
};

export default Solutions;
